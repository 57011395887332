<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Aset Bersertifikat </strong><small>Detail Aset</small>            
            <a href="/#/asetwilayah/list" class="float-right btn btn-secondary form-control-sm">Kembali</a>
            <a :href="linkedit" class="float-right btn btn-warning form-control-sm" style='margin-right:10px'>Edit Data</a>
            <a @click="modalAdd=true" class="float-right btn btn-danger form-control-sm" style='margin-right:10px'>Mutasi</a>
            <!-- <CButton type="button" @click="modalAdd=true" color="success" style='margin-right:15px'>Timeline Baru</CButton>  -->
            
          </CCardHeader>
          <CCardBody>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td style="width:20%">KODE SKPD</td>
                  <td style="width:30%">: <strong>{{kode_skpd}}</strong></td>
                  <td style="width:20%">NAMA SKPD</td>
                  <td style="width:30%">: <strong>{{nama_skpd}}</strong></td>
                </tr>
                
                <tr>
                  <td>KOTA/KABUPATEN</td>
                  <td>: <strong>{{kabupaten_kota}}</strong></td>
                  <td>KODE LOKASI</td>
                  <td>: <strong>{{kode_lokasi}}</strong></td>
                </tr>
                <tr>
                  <td>NAMA OPD</td>
                  <td>: <strong>{{nama_instansi}}</strong></td>
                  <td>LETAK/ALAMAT</td>
                  <td>: <strong>{{letak_lokasi}}</strong></td>
                </tr>
                <tr>
                  <td>KODE BARANG</td>
                  <td>: <strong>{{kode_barang}}</strong></td>
                  <td>NAMA BARANG</td>
                  <td>: <strong>{{nama_barang}}</strong></td>
                </tr>
                <tr>
                  <td>NO HAK</td>
                  <td>: <strong>{{no_hak}}</strong></td>
                  <td>TIPE HAK</td>
                  <td>: <strong>{{jenis_hak}}</strong></td>
                </tr>
                <tr>
                  <td>TANGGAL PEROLEHAN</td>
                  <td>: <strong>{{tgl_perolehan}}</strong></td>
                  <td>HARGA PEROLEHAN</td>
                  <td>: <strong>{{nilai_perolehan}}</strong></td>
                </tr>
                <tr>
                  <td>LUAS (m2)</td>
                  <td>: <strong>{{luas_tertulis}}</strong></td>
                  <td>TANGGAL SERTIFIKAT</td>
                  <td>: <strong>{{tgl_sertifikat}}</strong></td>
                </tr>
              </tbody>
            </table>

            <hr>
           <!--  <h4>Select News Source</h4>
          <input v-model="source" list="newssources-list" v-on:input="sourceChanged" 
                 name="source-selection" id="source-selection" class="form-control" 
                 placeholder="Please specify news source ..."/>
          <datalist id="newssources-list">
            <option v-for="source in sources" v-bind:value="source.kode_barang"  v-bind:label="source.kode_barang"></option>
          </datalist>
          <hr>
          <input v-model='newDeep' class='form-control' placeholder='newDeep'>
          <hr>
          <input v-model='newDeep2' class='form-control'  placeholder='newDeep2'>
          <hr> -->
          <!-- <table class='table table-bordered'>
            <thead>
              <tr>
                <th>No</th>
                <th>Kode</th>
                <th>Nama</th>
                <th>Instansi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="rs in deepSources">
                <td>-</td>
                <td>{{rs.kode_barang}}</td>
                <td>{{rs.nama_barang}}</td>
                <td>{{rs.nama_instansi}}</td>
              </tr>
            </tbody>
          </table> -->
            
            <!-- <label><b>FOTO ASET </b></label>                  -->
            <table class="table table-striped">
              <tr>
                <td>                  
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="foto_lokasi" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Foto Lokasi </b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="sertifikat" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Sertifikat </b> <a href='' target='_blank'>[Download]</a></label>
                  </div>
                </td>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;">
                    <img :src="buku_tanah" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Buku Tanah </b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
              </tr><br/>
              <tr>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="alas_hak" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Alas Hak</b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
                <td>
                  <!-- <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="bangunan" class="img-fluid" style="height: 100%;width: 100%;" />
                  </div> -->
                </td>
                <td></td>
              </tr>
            </table>
            <!-- <div class="form-group">
              <label> File Buku Tanah <a href='' target='_blank'>[Download]</a></label>
              <br />                  
              <div class="custom-file">
                <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
              </div>              
            </div> -->
            <!-- <div class="form-group" style="height: 400px;width: 100%;" >
              <label><b>Foto Aset </b></label><br/>                  
              <img :src="foto_lokasi" class="img-fluid" style="height: 100%;width: 100%;" />
            </div> -->
            
            <!-- <div style="height: 400px;width: 100%;" >
            <l-map ref="mapElement" :zoom="zoom" :center="center" style='height: 100%;width: 100%;'>
              <l-tile-layer :url="url"></l-tile-layer>
              <l-geo-json ref="track" :geojson="geojsonFile"  ></l-geo-json>
              <l-polygon :lat-lngs="latlngs" color="red"></l-polygon> --
            </l-map>
            </div> -->
            <div  class="mapboxgl-map"  style="height: 800px;width: 100%;padding:0;margin:0" >
              <div id="map"></div>
            </div>
            <br>
    </CCardBody>
  </CCard> 

            <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Riwayat Aset</h4> 
          </slot>
        </CCardHeader>
        <CCardBody>

          <table class="table  table-striped" id="datatable">
          <thead class="bg-info">
            <tr>
              <th>No </th>
              <th>Kode Barang </th>
              <th>Nama Barang </th>
              <th>Kota / Kabupaten </th>
              <th>Nama OPD Asal</th>
              <th>Nama OPD Tujuan</th>
              <!-- <th style="width:10%">No Hak </th> -->
              <th>Tanggal Perubahan </th>
              <th style="width:15%; class: text-center">Status Aset</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      :show.sync="myerror"
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>

    <CModal
      title="Data Mutasi Sertifikat"
      color="secondary"
      :show.sync="modalAdd"
    >
      <CInput
        v-model="tgl_mutasi"
        type="date"
        label="Tanggal Mutasi"
        placeholder="Input Tanggal Mutasi"
      /> 
      <CInput
        v-model="nama_opd_asal"
        label="Nama OPD Asal"
        placeholder="Input Nama OPD Asal"
      />
      <CInput
        v-model="nama_opd_tujuan"
        label="Nama OPD Tujuan"
        placeholder="Input Nama OPD Tujuan"
      />

        <div class="form-group">
          <label>Mutasi </label>
          <select v-model="status_mutasi" placeholder="Pilihan" class="form-control">
            <option value="" disabled selected>-- Pilihan --</option>
            <option value="1">Mutasi Keluar</option>
            <option value="2">Mutasi Antar OPD</option>
          </select>
        </div>
      
      <CInput
        v-model="ket_mutasi"
        label="Keterangan Mutasi"
        placeholder="Input Keterangan Mutasi"
      /> 

      <template #footer>
        <CButton @click="modalAdd = false" color="secondary">Batal</CButton>
        <CButton @click="updateRiwayatAset" color="success">Simpan</CButton>
      </template> 
    </CModal>
   
  </div>
</template>
<style scoped>
  /* .leaflet-control-attribution a{
    display: none !important
  } */
.map-container {
  position: relative;
  width: 100%;
  height: 95vh;
}

.mapboxgl-canvas {
  position: absolute;
  width: 100%; 
  height: 95vh;
 }
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.marker:before {
  content: "";
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  border: 1px solid #ccc;
  border-radius: 75% 45% 75% 0%;
  background: #3498db;
  bottom: 0;
  transform-origin: 0% 100%;
  transform: rotate(-45deg) scale(1);
}

.mapboxgl-popup{
  position:absolute;
  left: 50%;
  top:50%;
}

/* .mapboxgl-popup-content .list-group-item {
  padding: 0.5rem 0.25rem;
}
.mapboxgl-popup-content .list-group-item .label {
  padding-right: 0.5rem;
  font-weight: bold;
} */
</style>
<script>

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons';
import axios from 'axios';
import $ from 'jquery'; 
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  // components: {
  //   LMap,
  //   LTileLayer,
  //   LMarker,
  //   LPolygon,
  //   LGeoJson
  // },
  data () {
    return {
      linkedit :"/#/asetwilayah/edit/"+this.$route.params.id_aset_bersertifikat,
      placeholder :"",
      kode_skpd : "",
      nama_skpd : "",
      kabupaten_kota : "",
      kode_lokasi : "",
      latlngs: [[109.290056324599,-0.0124423568214555],[109.29122650719,-0.0160182734285108],[109.291621915063,-0.0158496126787141],[109.29045346265801,-0.0122778678589834],[109.290056324599,-0.0124423568214555]],
      nama_instansi : "",
      letak_lokasi : "",
      kode_barang : "",
      nama_barang : "",
      nama_opd_asal : "",
      nama_opd_tujuan : "",
      status_mutasi : "",
      tgl_mutasi : "",
      ket_mutasi : "",
      tgl_perolehan : "",
      nilai_perolehan : "",
      tgl_sertifikat : "",
      luas_tertulis : "",
      no_hak : "",
      jenis_hak : "",
      sub_hak : "",
      file : "",
      filename : "",
      modalAdd : false,
      myerror : false,
      placeholder : "Pilih File ",
      selected: [], // Must be an array reference!
      ruangans: [],
      lemaris: [],
      koloms: [],
      bariss: [],
      show: true,
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">BPN Kantah Kota Pontianak</a>',
      zoom: 14,
      center: [-0.0353948,109.2615099],
      centerGeojson: [],
      coordinates:[],
      details:{},
      geojsonFile : {},
      sources : [],      
      source: '',
      deepSource: '',
      deepSources: [],
      newDeep : '',
      newDeep2 : '',
    }
  },
  updated(){
    this.newDeep = this.source;
    this.newDeep2 = this.deepSource.nama_barang;
    this.deepSources.push(this.deepSource);

  },
  mounted(){
    this.showAsetwilayah();
    this.loadRiwayatAset();
    this.initMap();
    
   
    // alert(JSON.stringify(this.geojsonFile));
    //After building your geoJson layers, just call this:
  // this.$nextTick().then(() => {
  //     var group = new featureGroup();

  //     this.$refs.mapElement.mapObject.eachLayer(function(layer) {
  //         if (layer.feature != undefined)
  //             group.addLayer(layer);
  //     });

  //     this.$refs.mapElement.mapObject.fitBounds(group.getBounds(), { padding: [20, 20] });
  // });
    // var mymap = L.map(this.$refs['mapElement']).setView([51.505, -0.09], 13);
    // document.getElementsByClassName('leaflet-control-attribution').style.display = 'none';
    // map.attributionControl.setPrefix(false);
    // L.map('map', {attributionControl: false})
  },

  methods: {
    // toggle(id, koor, prop) {
      
    //   this.$store.commit('setProp', prop);
    //   this.$store.commit('setCoord', koor);
    //   this.$router.push("/asetwilayah/edit/"+id);
    // },

     sourceChanged: function(e) {
      console.log("source = "+this.source+" new value = "+e.target.value);
      var newSource = e.target.value;
     // only action if value is different from current deepSource 
     if (newSource!= this.deepSource.name) { 
       for (var i=0; i<this.sources.length; i++) {
         if (this.sources[i].kode_barang == newSource) {
           this.deepSource = this.sources[i];
          //  this.source = this.deepSource;

           console.log("Data Source"+JSON.stringify(this.deepSource));
         }
       }
      //  this.$emit('sourceChanged', this.deepSource.id);
     }
    },
    initMap() {
      // axios.get("http://139.180.219.157/intip/")
      //     .then((response) => {
      //           this.geojs =response.data;
      //       alert(this.geojs);
      // console.log(this.geojs);
                          
      //     });

      
      axios.get(process.env.VUE_APP_BASE_URL+"asetbersertifikat/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.sources=response.data.data;
              // alert(this.sources);
        });
  
      axios.get("https://sigagah.s3.ap-southeast-1.amazonaws.com/batas_kec.geojson")
          .then((response) => {
                this.geojs2 =response.data;
            // alert(this.geojs2);
      // console.log("geojsonbatas::",JSON.stringify(this.geojs2));
                          
          });
      mapboxgl.accessToken = "pk.eyJ1IjoibWluZ3lhbjIxNCIsImEiOiJja2h4Y2xiajAwMTBsMnZuNHBzMjVmYjlsIn0.w7Wxvvi2i4olHVL2gE1zkQ";
      this.map = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [109.2615099,-0.0353948],
        zoom: 12,
        // zoom: -5,
      });
      this.map.on("load", () => {
        this.map.addSource('batas', {
          'type': 'geojson',
          'data': 'https://sigagah.s3.ap-southeast-1.amazonaws.com/batas_kec.geojson'
          });
        this.map.addLayer({
          'id': 'batas',
          'type': 'fill',
          'source': 'batas',
          'paint': {
          'fill-color': '#888888',
          'fill-opacity': 0.2,
          'fill-outline-color': '#000',
          },
          });
        this.map.addControl(new mapboxgl.NavigationControl(), "top-left");
        this.map.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            trackUserLocation: true,
          }),
          "top-right"
        );
        this.map.addControl(
          new mapboxgl.ScaleControl({
            maxWidth: 100,
            unit: "metric",
          })
        );

        // if (this.markers.length > 0) {
        //   this.resetMarkers();
        // }

        // if (this.currentFeatures) {
        //   this.updateMarkers();
        // }
      });

      
    },
    doSomethingOnReady() {
      this.track = this.$refs.track.mapObject;
      this.map.fitBounds(this.track.getBounds(), {maxZoom: 13});
      console.log("Bounds:", this.track.getBounds());
    },
    findCenter:function(markers) {
      let lat = 0;
      let lng = 0;
      
      for(let i = 0; i < markers.length; ++i) {
          lat += markers[i].lat;
          lng += markers[i].lng;
      }

      lat /= markers.length;
      lng /= markers.length;

      return [lat,lng]
  },
    loadData: function(event){

      axios.get("http://139.180.219.157/earsip/api/public/raw_ruangan/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              // this.sources=response.data;
        });
      axios.get("http://139.180.219.157/earsip/api/public/raw_lemari/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.lemaris=response.data;
        });
      axios.get("http://139.180.219.157/earsip/api/public/raw_kolom/?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              this.koloms=response.data;
        });

    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.filename = fileData.name;
      this.file = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    }, 
    updateRiwayatAset: function(event){
      
      let us = JSON.parse(this.$store.state.users);
      const formData = new FormData()
      
      formData.append('id_aset_bersertifikat', this.$route.params.id_aset_bersertifikat)      
      formData.append('nama_instansi', this.nama_opd_tujuan)
      
      formData.append('tgl_mutasi', this.tgl_mutasi)
      formData.append('nama_opd_asal', this.nama_opd_asal)
      formData.append('nama_opd_tujuan', this.nama_opd_tujuan)
      formData.append('status_mutasi', this.status_mutasi)
      formData.append('ket_mutasi', this.ket_mutasi)

      axios.post(process.env.VUE_APP_BASE_URL+"addriwayataset", formData )
        .then((response) => {
          // alert(JSON.stringify(response.data));

          this.modalAdd=false;
            if(response.status==200){
              this.showAsetwilayah;
              this.loadRiwayatAset;
            }
            else{
              this.modalError = true;
            }  
            
        })
    },       
    showAsetwilayah: function(event){
      // Simple POST request with a JSON body using axios
      const asetwilayah = { 
                          kode_skpd: this.kode_skpd, 
                          nama_skpd: this.nama_skpd,
                          kabupaten_kota: this.kabupaten_kota,
                          kode_lokasi: this.kode_lokasi,
                          nama_instansi: this.nama_instansi,
                          letak_lokasi: this.letak_lokasi,
                          kode_barang: this.kode_barang,
                          nama_barang: this.nama_barang,
                          no_hak: this.no_hak,
                          jenis_hak: this.jenis_hak,
                          tgl_perolehan: this.tgl_perolehan,
                          nilai_perolehan: this.nilai_perolehan,
                          luas_tertulis: this.luas_tertulis,
                          tgl_sertifikat: this.tgl_sertifikat,
                          dikuasai: this.dikuasai,
                          nama_pendaftar: this.nama_pendaftar,
                          no_hp: this.no_hp,
                          jumlah_bidang: this.jumlah_bidang,
                          tgl_pendaftaran: this.tgl_pendaftaran,
                          foto_lokasi: this.foto_lokasi,
                          plang_tanah: this.plang_tanah,
                          pagar: this.pagar,
                          plang_tanah: this.plang_tanah
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"detailasetbersertifikat/"+this.$route.params.id_aset_bersertifikat+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
            
              this.kode_skpd=response.data.kode_skpd;
              this.nama_skpd=response.data.nama_skpd;
              this.kabupaten_kota=response.data.kabupaten_kota;
              this.kode_lokasi=response.data.kode_lokasi;
              this.nama_instansi=response.data.nama_instansi;
              this.letak_lokasi=response.data.letak_lokasi;
              this.kode_barang=response.data.kode_barang;
              this.nama_barang=response.data.nama_barang;
              this.no_hak=response.data.no_hak;
              this.jenis_hak=response.data.jenis_hak;
              this.tgl_perolehan=response.data.tgl_perolehan;
              this.nilai_perolehan=response.data.nilai_perolehan;  
              this.luas_tertulis=response.data.luas_tertulis;       
              this.tgl_sertifikat=response.data.tgl_sertifikat; 

              // this.no_hp=response.data.no_hp;       
              // this.tgl_pendaftaran=response.data.tgl_pendaftaran;       
              // this.jumlah_bidang=response.data.jumlah_bidang;       
              // this.dikuasai=response.data.dikuasai;       
              this.foto_lokasi=response.data.foto_lokasi;       
              // this.plang_tanah=response.data.plang_tanah;       
              // this.patok_batas=response.data.patok_batas;       
              // this.pagar=response.data.pagar;       
            // var newKoor = jsonpeta.geometry.coordinates;
            axios.get("https://sigagah.s3.ap-southeast-1.amazonaws.com/data_baru.geojson")
            .then((response) => {
              response.data.features.forEach(obj => {
                Object.entries(obj).forEach(() => {
                  if(obj.properties.NOMOR_HAK==this.no_hak){

                    
                  // alert("obj.properties::"+JSON.stringify(obj));
                    
                    this.map.addSource('bersertifikat', {
                    'type': 'geojson',
                    'data': JSON.parse(JSON.stringify(obj))
                    });
                    this.map.addLayer({
                    'id': 'bersertifikat',
                    'type': 'fill',
                    'source': 'bersertifikat',
                    'layout': {
                    // Make the layer visible by default.
                    'visibility': 'visible'
                    },
                    'paint': {
                    'fill-color': '#009900',
                    },
                    });

                    

                    const coordinates = obj.geometry.coordinates;
                    
                    // Create a 'LngLatBounds' with both corners at the first coordinate.
                    const bounds = new mapboxgl.LngLatBounds(
                    coordinates[0],
                    coordinates[0]
                    );
                    
                    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
                    for (const coord of coordinates) {
                    bounds.extend(coord);
                    }
                    
                    map.flyTo({
                    center: coord,
                    essential: true // this animation is considered essential with respect to prefers-reduced-motion
                    });
                    
                  }
                });
            });
            })
        })
    },
    loadRiwayatAset: function() {
    
      $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
               responsive: true,
              "ajax":{
                     "url": process.env.VUE_APP_BASE_URL+"riwayataset",
                     "dataType": "json",
                     "type": "POST"
                     },
              "columns": [
                  { "data": "no", class:"text-center"  },
                  { "data": "kode_barang", class:"text-center"  },
                  { "data": "nama_barang" , class:"text-center" },                  
                  { "data": "kabupaten_kota", class:"text-center" },
                  { "data": "nama_opd_asal", class:"text-center" },
                  { "data": "nama_opd_tujuan", class:"text-center" },
                  { "data": "tgl_mutasi", class:"text-center" },
                  { "data": "status_mutasi", class:"text-center" },
                  { "data": "ket_mutasi", class:"text-center" },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
    
    }
  }
}
</script>